@import '../typography.scss';
@import '../colors.scss';

.segment-menu {
  justify-content: flex-start;
  gap: 100px;
  padding-inline: 50px;

  ion-segment-button {
    max-width: fit-content;
    min-width: min-content;
    --padding-start: 0;
    --padding-end: 0;
    @include body-text-14($font-weight--bold);
    text-transform: capitalize;
    letter-spacing: normal;
    --indicator-height: 4px;

    ion-label {
      margin-block: 27px;
    }
  }

  &.mobile {
    overflow-x: scroll;
    gap: 44px;
    padding-inline: 0;

    ion-segment-button {
      --indicator-height: 0;

      ion-icon {
        fill: $color-dark-blue-400;
        margin-top: 0;
        margin-inline-end: 10px;
      }

      ion-label {
        color: $color-dark-blue-400;
        font-weight: normal;
        margin: 0;
      }

      &.segment-button-checked {
        ion-label {
          font-weight: 700;
          color: var(--ion-color-primary)
        }
      }

      &::part(native) {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-block: 27px;
      }
    }
  }
}

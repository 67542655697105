@import '../colors.scss';

ion-modal.modal--default {
  &::not(.mobile) {
    --height: 400px;
    --min-width: 700px;
  }

  ion-header {  
    .top-row {
      display: flex;
      justify-content: space-between;
      padding: 40px 143px 26px 24px;
      border-bottom: 1px solid $color-dark-blue-200;
    
      .start {
        display: flex;
        align-items: center;
      }
    
      .end {
        display: flex;
        align-items: center;
      }
    } 
  }


  ion-footer {
    display: flex;
    justify-content: center;
    gap: 16px;
    padding: 30px;
    border-top: 1px solid $color-dark-blue-200;
  }

  &.mobile {
    ion-footer {
      flex-direction: column;
      border-top: unset;
    }

    ion-header { 
      margin-bottom: 0;

      .top-row {
        border: unset;
      }
    }

    ion-content {
      .dropzone {
        border: unset;
        display: flex;
        align-items: center;

        .uploadText {
          position: static !important;
        }
      }

      ion-item.documentTypeChooser {
        ion-select {
          width: 100%;
        }
      }
    }
  }
}

ion-modal.modal--upload:not(.mobile) {
  --min-height: 656px;
  --min-width: 813px;

  ion-footer {
    display: unset;
    border: unset;
  }
}

html.plt-mobile,
html.plt-mobileview {
  ion-modal {
    padding-bottom: env(safe-area-inset-top);
  }

  ion-modal.add-admin-modal {
    --width: 100%;

    &::part(content) {
      margin-inline-start: 0;
    }

    div.ion-page {
      ion-header {
        div.top-row {
          flex-wrap: wrap;
          padding: 20px !important;
          overflow-x: hidden !important;

          div.end {
            ion-button {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}
@import '../colors.scss';
@import '../utils.scss';

.alert--default {
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(7.5px);

  .alert-wrapper {
    background-color: white;

    .alert-message {
      padding-bottom: 36px;
    }

    .alert-button-group {
      gap: 12px;
      padding: 0 25px 30px 25px;

      button {
        min-width: unset;
        height: $input-default-height;
        border-radius: $border-radius--large;
        padding-top: 15px;
        padding-bottom: 15px;
      }

      .alert-button-role-cancel {
        background: transparent;
        color: $color-dark-grey-700;
        border: 1px solid $color-dark-grey-300;
        font-weight: 700;
        filter: drop-shadow(6px 8px 25px rgba(11, 54, 72, 0.01));

        &:hover {
          background: $color-light-blue-25;
        }

        &:active {
          background: $color-light-blue-100;
        }

        &:focus {
          background: $color-light-blue-100;
        }
      }

      .alert-button-role-confirm {
        background: $color-light-blue-900;
        color: $color-pure-white;
        box-shadow: 8px 6px 15px rgba(0, 116, 148, 0.04), 6px 8px 25px rgba(1, 76, 96, 0.09);

        &:hover {
          background: $color-light-blue-500;
        }

        &:active {
          background: $color-light-blue-600;
        }

        &:focus {
          background: $color-light-blue-600;
        }
      }
    }
  }
}